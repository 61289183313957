import {addReducer} from "reactn";
import {SaveJobAction} from "../actions/jobAction";

addReducer('saveJob', (global, dispatch, action:SaveJobAction) => {
    return {
        savedJobs: global.savedJobs.concat(action.payload)
    }
});

addReducer('getJobs', (global, dispatch, action:SaveJobAction) => {
    return {
        savedJobs: global.savedJobs.concat(action.payload)
    }
});
