export enum Urls {
  Home = "/",
  JobList = "/job-list",
  JobDetails = "/job-details/:id",
  JobDetailsPreview = "/job-details-preview/:id",
  CompanyDetails = "/company-details/:id",
  Features = "/features",
  Faq = "/faq",
  Pricing = "/pricing",
  Team = "/team",
  CompanyDetailsSample = "/company-details-sample",
  AboutUs = "/about-us",
}

export enum EmailSubscriberType {
  NewsFromJobPortal = 0,
  NewsForNewJobs,
}
