import React from "reactn";
import { withRouter } from "react-router-dom";
import { config } from "../../config";
import { SelectField } from "../shared/SelectField";
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import { supportEmail, supportHeader, toAdminPortalJobPosting } from "../../helpers/supportHelper";
import { Urls } from "../../helpers/enums";

class Navigation extends React.PureComponent {
  state = {
    open: false,
    prevUrlLocation: this.props.location.pathname,
  };

  componentDidMount() {
    this.listenOnScroll();
  }

  componentDidUpdate() {
    this.setState({ prevUrlLocation: this.props.location.pathname });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", () => {});
  }

  openNavBar = () => {
    this.navigationBarRef.style.display = this.state.open ? "none" : "block";
    this.setState({ open: !this.state.open });
  };

  // toFindJobWizard = (e) => {
  //   e.preventDefault();
  //   const selectedLanguage = i18next.language;
  //   const url = `${config.platformUrl}/findJobWizard?origin=FromWizardJobPortal&language=${selectedLanguage}`;

  //   const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  //   if (newWindow) newWindow.opener = null;
  //   return null;
  // };

  isTabActive = (selectedTab) => {
    if (this.state.prevUrlLocation === selectedTab) {
      return "has-submenu active";
    } else {
      return "has-submenu";
    }
  };

  changeLanguage = (lng) => {
    this.props.i18n.changeLanguage(lng);
  };

  render() {
    let LANGUAGES = [
      { value: "en", label: i18next.t("language.EN") },
      { value: "si", label: i18next.t("language.SI") },
    ];

    const { t } = this.props;
    return (
      <header
        id="topnav"
        className={
          this.global.activeNav
            ? "defaultscroll scroll-active active scroll"
            : "defaultscroll scroll-active active"
        }
      >
        <div className="tagline">
          <div className="container">
            <div className="float-left">
              {/* TODO: Add contacts */}
              {/* <div className="phone">
                <i className="mdi mdi-phone-classic"></i> +1 800 123 45 67
              </div> */}
              <div className="email">
                <a href={supportHeader}>
                  <i className="mdi mdi-email"></i> {supportEmail}
                </a>
              </div>
            </div>
            <div className="float-right">
              <ul className="topbar-list list-unstyled d-flex" style={{ margin: "11px 0px" }}>
                {/* <li className="list-inline-item">
                  <a>
                    <i className="mdi mdi-account mr-2"></i>Benny Simpson
                  </a>
                </li> */}
                <li className="list-inline-item">
                  <a>
                    <SelectField
                      value={this.global.language}
                      option={LANGUAGES}
                      placeholder={t("language.LANGUAGES")}
                      onSelectChange={(val) => this.changeLanguage(val)}
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <a href={Urls.Home} className="logo">
              <img
                src="./talent-home-light.png"
                alt="Applicant Tracking System"
                className="logo-light"
                height="38"
              />
              <img
                src="./talent-home-black.png"
                alt="Applicant Tracking System"
                className="logo-dark"
                height="38"
              />
            </a>
            <a
              href={config.platformUrl}
              onClick={(e) => toAdminPortalJobPosting(e, i18next)}
              className="btn btn-custom btn-sm"
            >
              <i className="mdi mdi-cloud-upload"></i> {t("job.POST_A_JOB")}
            </a>
          </div>
          <div className="menu-extras" onClick={this.openNavBar}>
            <div className="menu-item">
              <a className={this.state.open ? "navbar-toggle open" : "navbar-toggle"}>
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </a>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div
              id="navigation"
              className="navigation-menu"
              ref={(ref) => (this.navigationBarRef = ref)}
            >
              <ul className="navigation-menu dropdown-navigation">
                <li className={this.isTabActive(Urls.Home)}>
                  <a href={Urls.Home}>{t("common.HOME")}</a>
                </li>
                <li className={this.isTabActive(Urls.AboutUs)}>
                  <a href={Urls.AboutUs}>{t("common.ABOUT_US")}</a>
                </li>
                <li className={this.isTabActive(Urls.Features)}>
                  <a href={Urls.Features}>{t("common.FEATURES")}</a>
                </li>
                <li className={this.isTabActive(Urls.Pricing)}>
                  <a href={Urls.Pricing}>{t("pricing.PRICING")}</a>
                </li>
                <li className={this.isTabActive(Urls.Faq)}>
                  <a href={Urls.Faq}>{t("common.FAQ")}</a>
                </li>
                <li className="has-submenu">
                  <a href="#">{t("common.EMPLOYERS")}</a>
                  <span className="menu-arrow"></span>
                  <ul className="submenu">
                    <li>
                      <a href={Urls.CompanyDetailsSample}>{t("company.COMPANY_DETAILS_SAMPLE")}</a>
                    </li>
                    <li>
                      <a href={Urls.Team}>{t("common.TEAM")}</a>
                    </li>
                  </ul>
                </li>
                <li className={this.isTabActive(Urls.JobList)}>
                  <a href={Urls.JobList}>{t("job.JOBS")}</a>
                  <span className="menu-arrow"></span>
                  <ul className="submenu dropdown-navigation">
                    <li>
                      <a href={Urls.JobList}>{t("job.JOB_LIST")}</a>
                    </li>
                    {/* TODO: Add back some options */}
                    {/* <li>
                    <a href="job-grid.html">{t("job.JOB_GRID")}</a>
                  </li>
                  <li>
                    <a href="job-details.html">{t("job.JOB_DETAILS")}</a>
                  </li> */}
                  </ul>
                </li>
                {/* <li className="has-submenu">
                <a href="#">{t("common.CANDIDATES")}</a>
                <span className="menu-arrow"></span>
                <ul className="submenu">
                  <li>
                    <a href="candidates-listing.html">{t("common.CANDIDATES_LISTING")}</a>
                  </li>
                  <li>
                    <a href="candidates-profile.html">{t("common.CANDIDATE_PROFILE")}</a>
                  </li>
                  <li>
                    <a href="create-resume.html">Create Resume</a>
                  </li>
                </ul>
              </li> */}
                {/* <li className="has-submenu">
                <a href="#">{t("blog.BLOG")}</a>
                <span className="menu-arrow"></span>
                <ul className="submenu">
                  <li>
                    <a href="blog-list.html">{t("blog.BLOG_LIST")}</a>
                  </li>
                  <li>
                    <a href="blog-details.html">{t("blog.BLOG_DETAILS")}</a>
                  </li>
                  <li>
                    <a href="blog-grid.html">{t("blog.BLOG_GRID")}</a>
                  </li>
                </ul>
              </li>
              <li className="has-submenu">
                <a href="#">{t("common.PAGES")}</a>
                <span className="menu-arrow"></span>
                <ul className="submenu">
                  <li>
                    <a href="about.html">{t("common.ABOUT_US")}</a>
                  </li>
                  <li>
                    <a href="services.html">{t("common.SERVICES")}</a>
                  </li>
                  <li>
                    <a href="team.html">{t("common.TEAM")}</a>
                  </li>
                  <li>
                    <a href="faq.html">{t("common.FAQ")}</a>
                  </li>
                  <li>
                    <a href="pricing.html">{t("common.PRICING_PLANS")}</a>
                  </li>
                  <li>
                    <a
                      href="#ModalCenter"
                      className="nav-link"
                      data-toggle="modal"
                      data-target="#ModalCenter"
                    >
                      {t("common.LOG_IN")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#ModalCenter1"
                      className="nav-link"
                      data-toggle="modal"
                      data-target="#ModalCenter1"
                    >
                      {t("common.REGISTER")}
                    </a>
                  </li>
                </ul>
              </li> */}
                {/* TODO: Add back contact */}
                <li className="has-submenu">
                  <a href={supportHeader}>{t("common.CONTACT")}</a>
                </li>
                {/* &nbsp; &nbsp;
              <a
                href={config.platformUrl}
                onClick={this.toFindJobWizard}
                className="btn btn-custom btn-sm"
              > 
                <i className="mdi mdi-briefcase-check"></i> {t("job.FIND_THE_JOB")}
              </a>*/}
              </ul>
            </div>
          </div>
        </div>
      </header>
    );
  }

  /**
   * Change style of navigation bar if user is not on top of the page
   */
  listenOnScroll = () => {
    window.addEventListener("scroll", () => {
      if (window.scrollY === 0) {
        this.dispatch.changeClassOnScroll({ ...this.global, activeNav: false });
      } else if (!this.global.activeNav) {
        this.dispatch.changeClassOnScroll({ ...this.global, activeNav: true });
      }
    });
  };
}

export default withRouter(withTranslation("translations")(Navigation));
