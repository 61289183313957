import './reducers/index'
import {State} from "reactn/default";




export function initialGlobalState(): State {
    return {
        activeNav: false,
        savedJobs: [],
        jobs: [],
        language: ''
    };
}
