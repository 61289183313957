import React from "react";
import { useTranslation } from "react-i18next";
import { Urls } from "../../helpers/enums";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer pt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="text-dark">
              <h3 className="mb-4 footer-list-title text-white f-17">Talent Home</h3>
              <p className="footer-desc">{t("footer.BETA_VERSION")}</p>
              {/* <ul className="footer-icons list-inline mb-4">
                <li className="list-inline-item">
                  <a href="#" className="">
                    <i className="mdi mdi-facebook"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#" className="">
                    <i className="mdi mdi-twitter"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#" className="">
                    <i className="mdi mdi-instagram"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#" className="">
                    <i className="mdi mdi-google"></i>
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
          <div className="col-lg-9">
            <div className="row">
              <div className="col-lg-4">
                <div>
                  <p className="text-white mb-4 footer-list-title f-17">{t("company.COMPANY")}</p>
                  <ul className="list-unstyled footer-list-menu">
                    <li>
                      <a href={Urls.AboutUs}>{t("common.ABOUT_US")}</a>
                    </li>
                    {/* <li>
                      <a href="#">Media & Press</a>
                    </li>
                    <li>
                      <a href="#">Career</a>
                    </li>
                    <li>
                      <a href="#">Blog</a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-4">
                <div>
                  <p className="text-white mb-4 footer-list-title f-17">{t("footer.RESOURCES")}</p>
                  <ul className="list-unstyled footer-list-menu">
                    <li>
                      <a href={Urls.Faq}>{t("footer.HELP_AND_SUPPORT")}</a>
                    </li>
                    {/* <li>
                      <a href="#">{t("footer.PRIVACY_POLICY")}</a>
                    </li>
                    <li>
                      <a href="#">{t("footer.TERMS_AND_CONDITIONS")}</a>
                    </li> */}
                  </ul>
                </div>
              </div>
              {/* <div className="col-lg-3">
                <div>
                  <p className="text-white mb-4 footer-list-title f-17">More Info</p>
                  <ul className="list-unstyled footer-list-menu">
                    <li>
                      <a href="#">Pricing</a>
                    </li>
                    <li>
                      <a href="#">For Marketing</a>
                    </li>
                    <li>
                      <a href="#">For CEOs </a>
                    </li>
                    <li>
                      <a href="#">For Agencies</a>
                    </li>
                  </ul>
                </div>
              </div> */}
              <div className="col-lg-4">
                <div>
                  <p className="text-white mb-4 footer-list-title f-17">
                    {t("footer.HELP_CENTER")}
                  </p>
                  <ul className="list-unstyled footer-list-menu">
                    <li>
                      <a href={Urls.Faq}>{t("common.FAQ")}</a>
                    </li>
                    <li>
                      <a href={Urls.Features}>{t("footer.FEATURES")}</a>
                    </li>
                    {/* <li>
                      <a href="#">General Question</a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
