interface Config {
  platformUrl: string | undefined; // url of second application
  api: string | undefined;
  googleApiKey: string;
  version:string;
}

const getUrlToRecruiterPortal = () => {
  if(process.env.REACT_APP_ENV === "DevelopmentLocal"){
    return process.env.REACT_APP_DEV_LOCAL_PLATFORM_URL
  }
  else if(process.env.REACT_APP_ENV === "Development"){
    return process.env.REACT_APP_DEV_PLATFORM_URL
  }
  else{
    return process.env.REACT_APP_MASTER_PLATFORM_URL
  }
}

const getUrlOfBackendServices = () => {
  if(process.env.REACT_APP_ENV === "DevelopmentLocal"){
    return process.env.REACT_APP_DEV_LOCAL_API
  }
  else if(process.env.REACT_APP_ENV === "Development"){
    return process.env.REACT_APP_DEV_API
  }
  else{
    return process.env.REACT_APP_MASTER_API
  }
}

export const config: Config = {
  platformUrl: getUrlToRecruiterPortal(),
  api: getUrlOfBackendServices(),
  googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "0x000000",
  version: JSON.stringify(require("../package.json").version)
};
