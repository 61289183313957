import React from "react";
import { config } from "../../config";

const FooterAlt = () => {
  return (
    <section className="footer-alt pt-3 pb-3">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <p className="copyright mb-0">2024 © Talent Home v {config.version}</p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default FooterAlt;
