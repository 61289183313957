import { config } from "../config";
import i18next from "i18next";
export const supportEmail = "support@talenthome.io";
export const supportHeader = `mailto:${supportEmail}`;

export const toAdminPortalJobPosting = (e) => {
  e.preventDefault();
  const selectedLanguage = i18next.language;
  const url = `${config.platformUrl}/jobs/0?language=${selectedLanguage}`;

  var newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
  return null;
};
