import React, {ChangeEvent} from 'react'
import {FormControl, InputGroup} from 'react-bootstrap'
import {Option} from "../../interfaces/SelectOption";


interface OptionView extends Option {
}

interface SelectFieldProps {
    option: OptionView[];
    icon?: string; // specify icon for select field
    placeholder?: string; // placeholder text label
    label?: string;
    value: string;
    className?: string;
    size?: 'sm' | 'lg';
    onSelectChange(val: string): void;
}


export class SelectField extends React.Component<SelectFieldProps, any> {

    get placeholderText(): string {
        return this.props.placeholder ? this.props.placeholder : '';
    }

    onSelectChange = (event: ChangeEvent<HTMLInputElement>): void => {
        this.props.onSelectChange(event.target.value);
    };

    render() {
        return (
            <InputGroup className={this.props.className || 'mb-1'}>
                    {this.props.icon ?
                        <InputGroup.Text id="basic-addon1">
                            <span className={this.props.icon}></span>
                        </InputGroup.Text> : null
                    }
                <FormControl
                    size={this.props.size || "sm"}
                    defaultValue={undefined}
                    style={{opacity: '1', color: '#999', height: '100%'}}
                    as="select"
                    value={this.props.value}
                    onChange={this.onSelectChange}>
                    {this.props.value === '' ?
                        <option key="placeholderTextUniKey" value={undefined}>
                            {this.placeholderText}
                        </option> : null}
                    {this.props.option.map((res, index) =>
                        <option key={index} value={res.value}>{res.label}</option>
                    )}
                </FormControl>
            </InputGroup>
        )
    }
}
