import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

// Define the base path for loading the locales
const basePath = process.env.NODE_ENV === "development" ? "" : "";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    preload: ["en", "si"],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      loadPath: `${basePath}/locales/{{lng}}/{{ns}}.json`,
    },
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    debug: false,
    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
