import {addReducer} from "reactn";

interface ChangeClassOnScrollAction {
    activeNav: boolean
}

interface ChangeLanguageAction {
    language: boolean
}

addReducer('changeClassOnScroll', (global: any, dispatch: any, action: ChangeClassOnScrollAction) => {
    return {
        activeNav: action.activeNav
    }
});

addReducer('changeLanguage', (global: any, dispatch: any, action: ChangeLanguageAction) => {
    return {
        language: action.language
    }
});
